<template>
  <b-modal
    id="DoctorHourModal"
    ref="DoctorHourModal"
    title="วันเวลาทำงานแพทย์"
    size="lg"
    centered
    @ok="updateDoctorWorkingHour"
    ok-title="บันทึก"
    cancel-title="ยกเลิก"
    @hidden="setDefaultValue"
  >
    <b-card no-body>
      <b-tabs card>
        <b-tab title="เวลาทำงาน">
          <b-row>
            <b-col>
              <b-form-group
                label="วันที่แพทย์ลงตรวจ"
                label-class="font-weight-bold"
              >
                <b-form-select
                  :class="{ 'is-invalid': $v.selectedDays.$error }"
                  v-model="$v.selectedDays.$model"
                  :options="optionDays"
                  text-field="label"
                  multiple
                  :select-size="7"
                ></b-form-select>
                <template v-slot:description>
                  <div></div>
                  <span v-if="$v.selectedDays.$error" class="error">
                    กรุณาเลือกวันทำงานอย่างน้อย 1 วัน
                  </span>
                  <div v-else>
                    กรุณาเลือกวันที่แพทย์ลงตรวจ Shift หรือ Ctrl
                    เพื่อเลือกหลายวัน
                    <div class="text-warning">
                      Ctrl + คลิก ซ้ำเพื่อยกเลิกวันที่เลือก
                    </div>
                  </div>
                </template>
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group
                label="เวลาเริ่มงาน:"
                label-class="font-weight-bold"
              >
                <b-input-group>
                  <datetime
                    class="w-100"
                    :class="{ 'is-invalid': $v.startTime.$error }"
                    type="time"
                    v-model="$v.startTime.$model"
                    input-class="form-control my-0 rounded"
                    value-zone="UTC+7"
                    format="HH:mm"
                    :phrases="{ ok: 'ตกลง', cancel: 'ยกเลิก' }"
                    :hour-step="1"
                    :minute-step="minuteStep"
                    auto
                  ></datetime>
                </b-input-group>
                <template v-slot:description> </template>
              </b-form-group>
              <b-form-group label="เวลาเลิกงาน:" label-class="font-weight-bold">
                <b-input-group>
                  <datetime
                    class="w-100"
                    type="time"
                    v-model="$v.endTime.$model"
                    input-class="form-control my-0 rounded "
                    value-zone="UTC+7"
                    format="HH:mm"
                    :phrases="{ ok: 'ตกลง', cancel: 'ยกเลิก' }"
                    :hour-step="1"
                    :minute-step="minuteStep"
                    auto
                  ></datetime>
                </b-input-group>
                <template v-slot:description>
                  <span v-if="$v.endTime.$error" class="error"
                    >กรุณาเลือกเวลาเลิกงาน ให้ช้ากว่าเวลาเริ่มงาน
                  </span>
                </template>
              </b-form-group>
              <b-button
                class="float-right aligned-self-baseline"
                :variant="editIndex === null ? 'primary' : 'warning'"
                @click="addToItems(0)"
                >{{
                  editIndex === null ? "เพิ่มเวลาลงตรวจ" : "แก้ไขเวลาลงตรวจ"
                }}</b-button
              >
            </b-col>
          </b-row>
        </b-tab>
        <b-tab title="เวลาทำงานตามสัปดาห์">
          <b-row>
            <b-col>
              <b-form-group
                label="วันที่แพทย์ลงตรวจ"
                label-class="font-weight-bold"
              >
                <b-form-select
                  :class="{ 'is-invalid': $v.selectedDay.$error }"
                  v-model="$v.selectedDay.$model"
                  :options="optionDays"
                  text-field="label"
                ></b-form-select>
                <template v-slot:description>
                  <span v-if="$v.selectedDay.$error" class="error">
                    กรุณาเลือกวันทำงาน
                  </span>
                </template>
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group
                description="เลือกสัปดาห์ที่ทำงาน เช่น ทำงานทุกวันเสาร์ที่ 1 และ 3 ของเดือน เป็นต้น"
                label="สัปดาห์ที่ลงตรวจ"
                label-class="font-weight-bold"
              >
                <b-form-select
                  :class="{ 'is-invalid': $v.setPos.$error }"
                  v-model="$v.setPos.$model"
                  :options="optionsetPos"
                  multiple
                  :select-size="5"
                ></b-form-select>
                <template v-slot:description> </template>
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group
                label="เวลาเริ่มงาน:"
                label-class="font-weight-bold"
              >
                <b-input-group>
                  <datetime
                    class="w-100"
                    :class="{ 'is-invalid': $v.startTime.$error }"
                    type="time"
                    v-model="$v.startTime.$model"
                    input-class="form-control my-0 rounded"
                    value-zone="UTC+7"
                    format="HH:mm"
                    :phrases="{ ok: 'ตกลง', cancel: 'ยกเลิก' }"
                    :hour-step="1"
                    :minute-step="minuteStep"
                    auto
                  ></datetime>
                </b-input-group>
                <template v-slot:description> </template>
              </b-form-group>
              <b-form-group label="เวลาเลิกงาน:" label-class="font-weight-bold">
                <b-input-group>
                  <datetime
                    class="w-100"
                    type="time"
                    v-model="$v.endTime.$model"
                    input-class="form-control my-0 rounded "
                    value-zone="UTC+7"
                    format="HH:mm"
                    :phrases="{ ok: 'ตกลง', cancel: 'ยกเลิก' }"
                    :hour-step="1"
                    :minute-step="minuteStep"
                    auto
                  ></datetime>
                </b-input-group>
                <template v-slot:description>
                  <span v-if="$v.endTime.$error" class="error"
                    >กรุณาเลือกเวลาเลิกงาน ให้ช้ากว่าเวลาเริ่มงาน
                  </span>
                </template>
              </b-form-group>
              <b-button
                class="float-right aligned-self-baseline"
                :variant="editIndex === null ? 'primary' : 'warning'"
                @click="addToItems(1)"
                >{{
                  editIndex === null ? "เพิ่มเวลาลงตรวจ" : "แก้ไขเวลาลงตรวจ"
                }}</b-button
              >
            </b-col>
          </b-row>
        </b-tab>
      </b-tabs>
    </b-card>

    <b-table
      outlined
      head-variant="light"
      :items="doctorWorkingHours"
      :fields="fields"
      hover
      responsive
      selectable
      show-empty
      select-mode="single"
      @row-clicked="assignItem"
    >
      <template #cell(index)="data">{{ data.index + 1 }}.</template>
      <template v-slot:cell(button)="data">
        <b-button
          class="show-when-hovered"
          size="xs"
          variant="outline-danger"
          @click="deleteItem(data.index)"
        >
          <i class="fa fa-trash-alt"></i>
        </b-button>
      </template>
      <template #empty>
        <h6 class="text-danger">กรุณาเพิ่มเวลาลงตรวจ</h6>
      </template>
    </b-table>
    <Loading v-if="isLoading"></Loading>
    <Dialog ref="Dialog"></Dialog>
  </b-modal>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Dialog from "@/components/modal/Dialog";
import Loading from "@/components/Loading";

import { datetime, RRule, RRuleSet, rruleStr } from "rrule";
import moment from "moment";

import {
  required,
  requiredIf,
  minLength,
  maxLength,
  between,
  sameAs,
  not,
  helpers,
  email,
} from "vuelidate/lib/validators";

export default {
  name: "DoctorHour",
  components: {
    Dialog,
    Loading,
  },
  data() {
    return {
      optionDays: [
        { label: "จันทร์", value: "MO" },
        { label: "อังคาร", value: "TU" },
        { label: "พุธ", value: "WE" },
        { label: "พฤหัส", value: "TH" },
        { label: "ศุกร์", value: "FR" },
        { label: "เสาร์", value: "SA" },
        { label: "อาทิตย์", value: "SU" },
      ],
      optionsetPos: [1, 2, 3, 4, 5],
      fields: [
        {
          key: "index",
          label: "",
        },
        {
          key: "days",
          label: "วันลงตรวจ",
          formatter: this.rruleToDays,
        },
        {
          key: "setpos",
          label: "สัปดาห์ลงตรวจ",
          formatter: this.rruleToSetpos,
        },
        {
          key: "startTime",
          label: "เวลาเริ่มงาน",
          formatter: this.formatTime,
        },
        {
          key: "endTime",
          label: "เวลาเลิกงาน",
          formatter: this.formatTime,
        },
        {
          key: "button",
          label: "",
        },
      ],
      branchMemberId: null,
      doctorWorkingHours: [],
      selectedDays: [],
      selectedDay: "",
      setPos: [],
      startTime: null,
      endTime: null,
      editIndex: null,
      isLoading: false,
    };
  },
  validations: {
    selectedDays: {
      required: requiredIf(function () {
        return !this.selectedDay;
      }),
      minLength: minLength(1),
    },
    selectedDay: {
      required: requiredIf(function () {
        return this.selectedDays.length === 0;
      }),
    },
    setPos: {},
    startTime: { required },
    endTime: {
      required,
      async isAfter(val) {
        let start = moment(this.startTime);
        let end = moment(this.endTime);
        return start.isBefore(end);
      },
    },
  },
  computed: {
    ...mapGetters({
      getBranchInfo: "moduleUser/getBranchInfo",
      getDoctorHour: "moduleUser/getDoctorHour",
    }),
    minuteStep() {
      return moment
        .duration(this.getBranchInfo?.confApptDuration || "00:15")
        .asMinutes();
    },
  },
  watch: {},
  methods: {
    ...mapActions({
      createAndUpdateDooctorWorkingHours:
        "moduleBranch/createAndUpdateDooctorWorkingHours",
      fetchBranchMember: "moduleBranch/fetchBranchMember",
    }),
    show({ branchMemberId, doctorWorkingHours }) {
      this.$bvModal.show("DoctorHourModal");
      this.startTime = moment(
        this.getBranchInfo?.confOpeningTime,
        "HH:mm"
      ).format();
      this.endTime = moment(
        this.getBranchInfo?.confClosingTime,
        "HH:mm"
      ).format();
      this.branchMemberId = branchMemberId;
      this.doctorWorkingHours = [...(doctorWorkingHours || [])];
    },
    close() {
      this.$bvModal.hide("ActionModal");
    },
    onRowSelected(item) {
      this.selected = item;
    },
    addToItems(tabIndex) {
      this.$v.$touch();
      if (this.$v.$error) return;
      let rule = new RRule({
        freq: RRule.MONTHLY,
        byweekday:
          tabIndex === 0
            ? this.selectedDays.map((i) => RRule[i])
            : [RRule[this.selectedDay]],
        bysetpos: this.setPos,
        dtstart: datetime(2022, 1, 1), //เริมตอนใช้โปรแกรมครั้งแรก
      });

      const dwh = {
        rruleStr: rule.toString(),
        startTime: moment(this.startTime).format("HH:mm"),
        endTime: moment(this.endTime).format("HH:mm"),
        branchMemberId: this.branchMemberId,
      };
      if (this.editIndex === null) {
        this.doctorWorkingHours.push(dwh);
      } else {
        this.$set(this.doctorWorkingHours, this.editIndex, dwh);
      }
      this.setDefaultValue();
    },
    deleteItem(index) {
      this.doctorWorkingHours.splice(index, 1);
      this.setDefaultValue();
    },
    assignItem(item, index) {
      const byDay = item.rruleStr.match(/BYDAY=([A-Z,]+)((;?)|($))/) || [];
      const bySetPos =
        item.rruleStr.match(/BYSETPOS=([0-9,]+)((;?)|($))/) || [];

      this.editIndex = index;
      this.selectedDays = byDay[1]?.split(",") || [];
      this.setPos = bySetPos[1]?.split(",") || [];
      this.startTime = moment(item.startTime, "HH:mm").format();
      this.endTime = moment(item.endTime, "HH:mm").format();
    },

    async updateDoctorWorkingHour(bvModalEvent) {
      bvModalEvent.preventDefault();
      if (this.doctorWorkingHours.length === 0) {
        this.$refs.Dialog.showAlertInfo(
          "กรุณาเพิ่มเวลาแพทย์ลงตรวจ",
          null,
          "warning"
        );
        return;
      }

      this.$refs.Dialog.showAlertConfirm(
        "บันทึกเวลาทำงานแพทย์ ?",
        `คุณต้องการบันทึกเวลาทำงานแพทย์"`
      ).then(async (result) => {
        try {
          if (result.value) {
            this.isLoading = true;
            let clinicUrl = this.$route.params.clinicUrl;
            let branchUrl = this.$route.params.branchUrl;
            await this.createAndUpdateDooctorWorkingHours({
              doctorWorkingHours: this.doctorWorkingHours.map((i) => {
                let dwh = this.filterObject(i, [
                  "rruleStr",
                  "startTime",
                  "endTime",
                  "branchMemberId",
                ]);
                return { ...dwh, branchMemberId: this.branchMemberId };
              }),
              clinicUrl,
              branchUrl,
            });
            this.$store.dispatch("moduleUser/fetchDoctorList", {
              clinicUrl,
              branchUrl,
            });
            this.$emit("getMember");
          }
        } catch (err) {
          console.error(err);
        } finally {
          this.isLoading = false;
          this.$bvModal.hide("DoctorHourModal");
        }
      });
    },
    rruleToDays(value, key, item) {
      return item.rruleStr.match(/BYDAY=([A-Z,]+)((;?)|($))/)[1] || "";
    },
    rruleToSetpos(value, key, item) {
      let setPos = item.rruleStr.match(/BYSETPOS=([0-9,]+)((;?)|($))/);
      if (setPos) return setPos[1];
      return "";
    },
    formatTime(t) {
      return moment(t, "HH:mm:ss").format("HH:mm");
    },
    async setDefaultValue() {
      this.editIndex = null;
      this.selectedDays = [];
      this.selectedDay = "";
      this.setPos = [];
      // this.branchMemberId = null;
      this.startTime = moment(
        this.getBranchInfo?.confOpeningTime,
        "HH:mm"
      ).format();
      this.endTime = moment(
        this.getBranchInfo?.confClosingTime,
        "HH:mm"
      ).format();
      await this.$nextTick();
      this.$v.$reset();
    },
    filterObject(obj, keysToKeep) {
      const { ...filteredObj } = obj;
      for (const key in filteredObj) {
        if (!keysToKeep.includes(key)) {
          delete filteredObj[key];
        }
      }
      return filteredObj;
    },
  },
};
</script>

<style>
</style>