var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"content-wrapper view-user-manage pt-3"},[_c('div',{staticClass:"content"},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-body"},[_vm._m(0),_c('b-card',{attrs:{"no-body":""}},[_c('b-tabs',{attrs:{"card":""}},[(_vm.isManager)?_c('b-tab',{attrs:{"title":"ผู้ใช้งานทั้งหมด","active":""}},[_c('b-button',{staticClass:"mb-2",attrs:{"variant":"primary"},on:{"click":_vm.openNewUserModal}},[_c('i',{staticClass:"fa fa-user-plus",attrs:{"aria-hidden":"true"}}),_vm._v(" เพิ่มผู้ใช้งาน ")]),_c('div',[_c('b-table',{style:({ minHeight: '300px' }),attrs:{"outlined":"","noCollapse":"","sort-icon-left":"","sort-by":"creationDt","sort-desc":false,"head-variant":"light","items":_vm.branchMembers,"fields":_vm.fields,"hover":"","responsive":""},scopedSlots:_vm._u([{key:"cell(index)",fn:function(data){return [_vm._v(_vm._s(data.index + 1)+".")]}},{key:"cell(fullName)",fn:function(data){return [_vm._v(" "+_vm._s(data.value)+" "),(
                        data.item.user &&
                        data.item.user.email == _vm.userInfo.email
                      )?_c('span',{staticClass:"bg-secondary rounded p-1 mx-1"},[_vm._v("คุณ")]):_vm._e()]}},{key:"cell(authId)",fn:function(data){return [_c('b-row',[_c('b-col',[_c('multiselect',{attrs:{"multiple":"","track-by":"val","label":"label","value":"val","placeholder":"กรุณาเลือกตำแหน่ง","selectLabel":"เลือก","selectedLabel":"เลือกแล้ว","deselect-label":"คลิกเพื่อลบ","options":_vm.rolesListTable(data.item),"searchable":false,"allow-empty":true,"closeOnSelect":false},on:{"input":function($event){return _vm.editRoles(data.item)}},model:{value:(data.item.multiSelectRoles),callback:function ($$v) {_vm.$set(data.item, "multiSelectRoles", $$v)},expression:"data.item.multiSelectRoles"}})],1),(data.item.rolesEdited)?_c('b-col',{staticClass:"d-flex align-items-center justify-content-md-end",attrs:{"md":"2"}},[_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],attrs:{"size":"sm","variant":"outline-danger","title":"ยกเลิกการแก้ไขตำแหน่ง"},on:{"click":function($event){return _vm.resetRoles(data.item)}}},[_c('i',{staticClass:"fas fa-times-circle"})]),_c('span',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip"}],staticClass:"d-inline-block",attrs:{"title":"กรุณาเลือกอย่างน้อย 1 ตำแหน่ง","disabled":data.item.multiSelectRoles.length >= 1}},[_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"ml-1",attrs:{"size":"sm","variant":"outline-success","title":"บันทึกการแก้ไขตำแหน่ง","disabled":data.item.multiSelectRoles.length < 1},on:{"click":function($event){return _vm.updateRoles(data.item)}}},[_c('i',{staticClass:"fas fa-check-circle"})])],1)],1):_vm._e()],1)]}},{key:"cell(delete)",fn:function(data){return [(
                        data.item.user &&
                        data.item.user.email != _vm.userInfo.email
                      )?_c('b-button',{staticClass:"show-when-hovered",attrs:{"size":"xs","variant":"outline-danger"},on:{"click":function($event){return _vm.deleteMember(data.item.user.uid)}}},[_c('i',{staticClass:"fa fa-trash-alt"})]):_vm._e()]}}],null,false,3360401965)})],1)],1):_vm._e(),_c('b-tab',{attrs:{"title":"เวลาลงตรวจแพทย์"}},[_c('b-table',{style:({ minHeight: '300px' }),attrs:{"outlined":"","noCollapse":"","sort-icon-left":"","sort-by":"creationDt","sort-desc":false,"head-variant":"light","items":_vm.doctorMembers,"fields":_vm.doctorTableField,"hover":"","responsive":""},scopedSlots:_vm._u([{key:"cell(index)",fn:function(data){return [_vm._v(_vm._s(data.index + 1)+".")]}},{key:"cell(fullName)",fn:function(data){return [_vm._v(" "+_vm._s(data.value)+" "),(
                      data.item.user && data.item.user.email == _vm.userInfo.email
                    )?_c('span',{staticClass:"bg-secondary rounded p-1 mx-1"},[_vm._v("คุณ")]):_vm._e()]}},{key:"cell(drApptColor)",fn:function(data){return [(_vm.isUserDoctor(data.item.authIdItems))?_c('VSwatches',{attrs:{"swatches":_vm.doctorColorListCode,"row-length":"5","swatch-size":"31","trigger-style":{ width: '31px', height: '31px' }},on:{"input":function($event){return _vm.updateMember(data.item)}},model:{value:(data.item.drApptColor),callback:function ($$v) {_vm.$set(data.item, "drApptColor", $$v)},expression:"data.item.drApptColor"}}):_vm._e()]}},{key:"cell(button)",fn:function(data){return [_c('b-button',{attrs:{"size":"sm","variant":data.item.doctorWorkingHours.length > 0
                        ? 'outline-info'
                        : 'outline-warning'},on:{"click":function($event){return _vm.openDoctorHourModal(data.item)}}},[_c('i',{staticClass:"fas fa-clock"})])]}}])})],1)],1)],1)],1)])]),_c('DoctorHourModal',{ref:"DoctorHourModal",on:{"getMember":_vm.getMember}}),_c('NewUserModal',{ref:"newUserModal",attrs:{"branchMembers":_vm.branchMembers},on:{"getMember":_vm.getMember}}),_c('Dialog',{ref:"Dialog"}),(_vm.isLoading)?_c('Loading'):_vm._e()],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('h4',[_c('i',{staticClass:"fas fa-users"}),_vm._v(" จัดการผู้ใช้")])}]

export { render, staticRenderFns }