<template>
  <div class="content-wrapper view-user-manage pt-3">
    <div class="content">
      <div class="card">
        <div class="card-body">
          <h4><i class="fas fa-users"></i> จัดการผู้ใช้</h4>
          <!-- add user -->
          <b-card no-body>
            <b-tabs card>
              <b-tab title="ผู้ใช้งานทั้งหมด" active v-if="isManager">
                <b-button
                  class="mb-2"
                  variant="primary"
                  @click="openNewUserModal"
                >
                  <i class="fa fa-user-plus" aria-hidden="true"></i>
                  เพิ่มผู้ใช้งาน
                </b-button>

                <!-- table -->
                <div>
                  <b-table
                    :style="{ minHeight: '300px' }"
                    outlined
                    noCollapse
                    sort-icon-left
                    sort-by="creationDt"
                    :sort-desc="false"
                    head-variant="light"
                    :items="branchMembers"
                    :fields="fields"
                    hover
                    responsive
                  >
                    <template #cell(index)="data"
                      >{{ data.index + 1 }}.</template
                    >
                    <template #cell(fullName)="data">
                      {{ data.value }}
                      <span
                        v-if="
                          data.item.user &&
                          data.item.user.email == userInfo.email
                        "
                        class="bg-secondary rounded p-1 mx-1"
                        >คุณ</span
                      >
                    </template>
                    <template v-slot:cell(authId)="data">
                      <b-row>
                        <b-col>
                          <multiselect
                            v-model="data.item.multiSelectRoles"
                            multiple
                            track-by="val"
                            label="label"
                            value="val"
                            placeholder="กรุณาเลือกตำแหน่ง"
                            selectLabel="เลือก"
                            selectedLabel="เลือกแล้ว"
                            deselect-label="คลิกเพื่อลบ"
                            :options="rolesListTable(data.item)"
                            :searchable="false"
                            :allow-empty="true"
                            :closeOnSelect="false"
                            @input="editRoles(data.item)"
                          ></multiselect>
                        </b-col>

                        <b-col
                          md="2"
                          v-if="data.item.rolesEdited"
                          class="d-flex align-items-center justify-content-md-end"
                        >
                          <b-button
                            size="sm"
                            variant="outline-danger"
                            v-b-tooltip.hover
                            title="ยกเลิกการแก้ไขตำแหน่ง"
                            @click="resetRoles(data.item)"
                          >
                            <i class="fas fa-times-circle"></i>
                          </b-button>
                          <span
                            class="d-inline-block"
                            v-b-tooltip
                            title="กรุณาเลือกอย่างน้อย 1 ตำแหน่ง"
                            :disabled="data.item.multiSelectRoles.length >= 1"
                          >
                            <b-button
                              class="ml-1"
                              size="sm"
                              variant="outline-success"
                              v-b-tooltip.hover
                              title="บันทึกการแก้ไขตำแหน่ง"
                              @click="updateRoles(data.item)"
                              :disabled="data.item.multiSelectRoles.length < 1"
                            >
                              <i class="fas fa-check-circle"></i>
                            </b-button>
                          </span>
                        </b-col>
                      </b-row>
                    </template>

                    <template v-slot:cell(delete)="data">
                      <b-button
                        class="show-when-hovered"
                        size="xs"
                        variant="outline-danger"
                        @click="deleteMember(data.item.user.uid)"
                        v-if="
                          data.item.user &&
                          data.item.user.email != userInfo.email
                        "
                      >
                        <i class="fa fa-trash-alt"></i>
                      </b-button>
                    </template>
                  </b-table>
                </div>
              </b-tab>
              <b-tab title="เวลาลงตรวจแพทย์">
                <b-table
                  :style="{ minHeight: '300px' }"
                  outlined
                  noCollapse
                  sort-icon-left
                  sort-by="creationDt"
                  :sort-desc="false"
                  head-variant="light"
                  :items="doctorMembers"
                  :fields="doctorTableField"
                  hover
                  responsive
                >
                  <template #cell(index)="data">{{ data.index + 1 }}.</template>
                  <template #cell(fullName)="data">
                    {{ data.value }}
                    <span
                      v-if="
                        data.item.user && data.item.user.email == userInfo.email
                      "
                      class="bg-secondary rounded p-1 mx-1"
                      >คุณ</span
                    >
                  </template>
                  <template v-slot:cell(drApptColor)="data">
                    <VSwatches
                      v-if="isUserDoctor(data.item.authIdItems)"
                      v-model="data.item.drApptColor"
                      :swatches="doctorColorListCode"
                      row-length="5"
                      swatch-size="31"
                      :trigger-style="{ width: '31px', height: '31px' }"
                      @input="updateMember(data.item)"
                    ></VSwatches>
                  </template>
                  <template v-slot:cell(button)="data">
                    <b-button
                      size="sm"
                      :variant="
                        data.item.doctorWorkingHours.length > 0
                          ? 'outline-info'
                          : 'outline-warning'
                      "
                      @click="openDoctorHourModal(data.item)"
                    >
                      <i class="fas fa-clock"></i>
                    </b-button>
                  </template>
                </b-table>
              </b-tab>
            </b-tabs>
          </b-card>
        </div>
      </div>
    </div>
    <DoctorHourModal ref="DoctorHourModal" @getMember="getMember" />
    <NewUserModal
      ref="newUserModal"
      :branchMembers="branchMembers"
      @getMember="getMember"
    />
    <Dialog ref="Dialog"></Dialog>
    <Loading v-if="isLoading"></Loading>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

import NewUserModal from "@/components/modal/NewUser";

import Dialog from "@/components/modal/Dialog";
import DoctorHourModal from "@/components/modal/DoctorHour";
import Loading from "@/components/Loading";

import VSwatches from "vue-swatches";
// Import the styles too, typically in App.vue or main.js
import "vue-swatches/dist/vue-swatches.css";
import "@/theme/UserManage.scss";

export default {
  name: "UserManage",
  components: {
    Dialog,
    Loading,
    VSwatches,
    NewUserModal,
    DoctorHourModal,
  },
  data() {
    return {
      isLoading: false,
      branchMembers: [],
      searchEmail: "",
      searchEmailStatus: false,
      branchMember: null,
      branchAuthority: [],
      branchAuthorityMulti: [],
      drApptColor: "",
      rolesList: [],
      fields: [
        {
          key: "index",
          label: "",
        },
        {
          key: "fullName",
          label: "ชื่อ",
          sortable: true,
          class: "narrow-spacing",
        },
        {
          key: "user.email",
          label: "อีเมล",
          sortable: true,
          class: "narrow-spacing",
        },
        {
          key: "authId",
          label: "ตำแหน่ง",
          // class: "tdRoles",
          class: "text-center p-0 align-middle tdRoles narrow-spacing",
        },
        {
          key: "delete",
          label: "",
          class: "text-center  align-middle",
        },
      ],
      doctorTableField: [
        {
          key: "index",
          label: "",
        },
        {
          key: "fullName",
          label: "ชื่อ",
          sortable: true,
          class: "narrow-spacing",
        },

        {
          key: "drApptColor",
          label: "สี",
          class: "text-center  align-middle",
        },
        {
          key: "button",
          label: "วันเวลาทำงาน",
          class: "text-center  align-middle",
        },
      ],
      state: null,
      invalidFeedback: "",
      validFeedback: "",
    };
  },
  created() {
    this.init();
  },
  computed: {
    ...mapGetters({
      branchAuthorityList: "moduleMaster/getBranchAuthorityList",
      branchAuthorityBy: "moduleMaster/getBranchAuthorityBy",
      doctorColorList: "moduleMaster/getDoctorColorList",
      userInfo: "moduleUser/getUserInfo",
      isDoctor: "moduleUser/isDoctor",
      isManager: "moduleUser/isManager",
    }),
    doctorColorListCode() {
      return this.doctorColorList.map((item) => item.code);
    },
    doctorMembers() {
      return this.branchMembers.filter((i) => i.authIdItems?.includes(2));
    },
  },
  watch: {
    searchEmail() {
      this.searchEmailStatus = false;
      this.state = null;
    },
    branchAuthorityMulti() {
      this.branchAuthority = this.branchAuthorityMulti.map((item) => item.val);
    },
  },
  methods: {
    ...mapActions({
      fetchBranchMember: "moduleBranch/fetchBranchMember",
      checkBranchMember: "moduleBranch/checkBranchMember",
      updateBranchMember: "moduleBranch/updateBranchMember",
      deleteBranchMember: "moduleBranch/deleteBranchMember",
    }),
    async init() {
      this.getMember();
    },
    async getMember() {
      this.isLoading = true;
      this.fetchBranchMember({
        clinicUrl: this.$route.params.clinicUrl,
        branchUrl: this.$route.params.branchUrl,
      })
        .then((res) => {
          if (res.status == "200") {
            this.branchMembers = res.data;
            this.branchMembers.forEach((member) => {
              member.fullName = member.user.fullName;
              this.$set(member, "multiSelectRoles", []);
              this.$set(member, "rolesEdited", false);
              member.authIdItems.forEach((i) => {
                member.multiSelectRoles.push(this.branchAuthorityBy(i, "val"));
              });
            });
          } else {
            this.branchMembers = [];
          }
          this.isLoading = false;
        })
        .catch(() => {
          this.isLoading = false;
        });
    },

    updateMember(branchMember) {
      if (branchMember) {
        let data = {
          uid: branchMember.uid,
          authIdItems: branchMember.authIdItems,
          drApptColor: branchMember.drApptColor,
        };
        this.isLoading = true;
        this.updateBranchMember({
          member: data,
          clinicUrl: this.$route.params.clinicUrl,
          branchUrl: this.$route.params.branchUrl,
        })
          .then((res) => {
            if (res.status == "200") {
              this.getMember();
              this.setDefaultSearch();
            }
            this.isLoading = false;
          })
          .catch(() => {
            this.isLoading = false;
          });
      }
    },
    deleteMember(userId) {
      this.$refs.Dialog.showAlertConfirm(
        "ลบผู้ใช้งาน ?",
        "คุณต้องการลบผู้ใช้งาน"
      ).then((result) => {
        if (result.value) {
          this.isLoading = true;
          this.deleteBranchMember({
            uid: userId,
            clinicUrl: this.$route.params.clinicUrl,
            branchUrl: this.$route.params.branchUrl,
          })
            .then((res) => {
              if (res.status == "200") {
                this.getMember();
              }
              this.isLoading = false;
            })
            .catch(() => {
              this.isLoading = false;
            });
        }
      });
    },
    customLabel(libs) {
      return `${libs.label}`;
    },
    setDefaultSearch() {
      this.searchEmail = "";
      this.searchEmailStatus = false;
      this.branchMember = null;
      this.branchAuthority = [];
      this.branchAuthorityMulti = [];
      this.drApptColor = "";
      this.rolesList = [];
    },
    isUserDoctor(arr) {
      return arr.find((item) => item === 2);
    },
    rolesListTable(member) {
      if (member.user?.userTypeId === 1) {
        return this.branchAuthorityList.filter(
          //ตัดหมอออกจาก list
          (item) => item.val != 2
        );
      } else {
        return this.branchAuthorityList;
      }
    },
    editRoles(member) {
      member.rolesEdited = true;
    },
    resetRoles(member) {
      member.multiSelectRoles = member.authIdItems.map((i) =>
        this.branchAuthorityBy(i, "val")
      );
      member.rolesEdited = false;
    },
    updateRoles(member) {
      if (
        this.isMatchedUserLogin(member) &&
        !this.isManagerRoleApplied(member)
      ) {
        this.$refs.Dialog.showAlertInfo(
          "ไม่สามารถลบตำแหน่งผู้จัดการได้",
          "ไม่สามารถลบตำแหน่งผู้จัดการ ออกจากตัวเองได้",
          "error"
        ).then(() => {
          this.resetRoles(member);
        });
      } else {
        let rolesStr = member.multiSelectRoles.map((i) => i.label).join(", ");

        this.$refs.Dialog.showAlertConfirm(
          "แก้ไขตำแหน่งผู้ใช้งาน ?",
          `คุณต้องการแก้ไข "${member.user.fullName}" ให้เป็นตำแหน่ง "${rolesStr}"`
        ).then((result) => {
          if (result.isConfirmed) {
            member.authIdItems = member.multiSelectRoles
              .map((i) => i.val)
              .filter(Boolean); // [,5,4,3,2,1] remove[0]
            this.updateMember(member);
            member.rolesEdited = false;
          } else {
            this.resetRoles(member);
          }
        });
      }
    },
    isMatchedUserLogin(member) {
      return member.uid === this.userInfo.uid;
    },
    isManagerRoleApplied(member) {
      return member.multiSelectRoles.find((i) => i.val === 1);
    },
    openNewUserModal() {
      this.$refs.newUserModal.show();
    },
    openDoctorHourModal({ id, doctorWorkingHours }) {
      this.$refs.DoctorHourModal.show({
        branchMemberId: id,
        doctorWorkingHours,
      });
    },
  },
  destroyed() {
    this.$store.dispatch("moduleUser/fetchAllBranchMembers", {
      clinicUrl: this.$route.params.clinicUrl,
      branchUrl: this.$route.params.branchUrl,
    });
  },
};
</script>

<style>
.tdRoles {
  width: 400px;
}
</style>
